import React from "react"
import { Box, Flex } from "@chakra-ui/core"

const Video = ({ videoUrl }) => {
  return (
    <Flex h="100%" w={["100%", "100%", "70%"]} align="center" justify="center">
      <Box
        pos="relative"
        w={["88%", "89%", "80%"]}
        pt={["49.975%", "50.075%", "44.975%"]}
      >
        <Box
          as="iframe"
          src={videoUrl}
          allowFullScreen
          w={["100%"]}
          h={["100%"]}
          // h={["39.365%"]}
          frameborder="0"
          pos="absolute"
          top="0"
          left="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        />
      </Box>
    </Flex>
  )
}

export default Video
