/*
Ticket: #20

Component with Left sided image-slider and right sided text
  
Props:
  images: array
  text: string

*/

import React, { useState } from "react"
import Img from "gatsby-image"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { Box, Flex, Icon, Text as T } from "@chakra-ui/core"

import { useSpring, animated } from "react-spring"
// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks,
// } from "body-scroll-lock"

import theme from "root/theme"
import ImageSlider from "components/imageSlider"
import Text from "components/lightboxText"
import Arrow from "./arrow_right.inline.svg"
import Video from "components/video"

export const StyledArrow = styled(Arrow)`
  height: 2.5em;
  width: auto;
  position: absolute;
  top: 48%;
  right: 4em;
  z-index: 1000;

  @media (min-width: 48em) {
    height: 2em;
    right: 4em;
  }

  @media (min-width: 75em) {
    height: 1.5em;
    right: 3em;
  }
`

// const StyledArrowLeft = styled(StyledArrow)`
//   transform: rotate(180deg);
//   transform-origin: center center;
//   left: 2em;
// `

// const StyledArrowRight = styled(StyledArrow)`
//   right: 2em;
// `

const AnimatedFrame = styled(animated.div)`
  display: flex;
  position: fixed;
  top: 0vh;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  flex-wrap: wrap;
  overflow: hidden;
`

const AnimatedIconsFrame = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
`

const Lightbox = ({
  images,
  text,
  title,
  subtitle,
  author,
  visibleFunc,
  visible = false,
  animate,
  animationStyle,
  nextItem,
  imageTitle,
  nextItemArrow,
  viewImageInfo,
  videoUrl,
  useAlternativeFont = false,
}) => {
  const [viewText, setViewText] = useState(true)
  const stylePropsFrame = useSpring({
    top: visible || !animate ? "0%" : "100%",
  })
  const styleProps = useSpring({
    position: "absolute",
    top: viewText ? "-100%" : "0%",
  })

  const handleSetViewText = () => {
    setViewText(state => !state)
  }

  const noImages = images && images.length === 0
  const isVideo = !!videoUrl

  return (
    <AnimatedFrame
      // ref={scrollLockInitiator}
      style={{ ...stylePropsFrame, ...animationStyle }}
    >
      <Global
        styles={css`
          body::-webkit-scrollbar {
            display: ${visible ? "none" : ""};
          }
        `}
      />
      <Flex
        align="center"
        bg="green"
        w={["100%", "100%", text ? (noImages ? "100%" : "50%") : "100%"]}
        h="100%"
        pos="relative"
        className="imageSlider"
      >
        <T
          className="image-title"
          pos="absolute"
          bottom={["-0.15em", "-0.15em", "0em"]}
          left={["0.5em", "0.5em", "1em"]}
          transform="rotate(-90deg)"
          transformOrigin="top left"
          color="white"
          fontSize={["5-5xl", "5xl", "3xl"]}
        >
          {imageTitle}
        </T>

        {isVideo && visible && <Video videoUrl={videoUrl} />}

        {images && images.length > 0 && (
          <ImageSlider
            images={images}
            viewImageInfo={viewImageInfo}
            mouseEvents={!viewText}
          >
            {images.map((image, index) => (
              <Box h="100%" key={index}>
                {image && (
                  <Img
                    alt={image.alt}
                    fluid={
                      image.imageFile && image.imageFile.childImageSharp.fluid
                    }
                    style={{ height: "100%" }}
                    imgStyle={{
                      objectFit: "contain",
                      margin: 0,
                      objectPosition: "center center",
                    }}
                  />
                )}

                {image.caption && (
                  <Box
                    fontFamily="Traulha"
                    color="brown"
                    pt="0.2em"
                    fontSize={["3xl", "2xl", "md"]}
                  >
                    {image.caption}
                  </Box>
                )}
              </Box>
            ))}
          </ImageSlider>
        )}
      </Flex>

      {text && (
        <animated.div
          style={{
            ...styleProps,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        >
          <Box
            color="green"
            fontSize={["3xl", "2xl", "md"]}
            pt={["2em", "1em", "3em"]}
            w={["100%", "100%", isVideo ? "30%" : "50%"]}
            bg="white"
            h="100vh"
            pos="relative"
            left={["0", "0", isVideo ? "70%" : "50%"]}
            top={["100%", "100%", "100%"]}
            overflow={["scroll", "scroll", "auto"]}
            pointerEvents="all"
            zIndex="2"
            css={css`
              & {
                -webkit-overflow-scrolling: touch;
              }
            `}
          >
            <Text
              text={text}
              title={title}
              author={author}
              subtitle={subtitle}
              useAlternativeFont={useAlternativeFont}
            />
          </Box>
        </animated.div>
      )}
      <AnimatedIconsFrame style={stylePropsFrame}>
        <Box
          w="10%"
          h="100%"
          pos="absolute"
          right="0"
          onClick={nextItem}
          pointerEvents="all"
          display={nextItemArrow ? ["none", "none", "block"] : "none"}
          css={css`
            @media (hover) {
              cursor: pointer;

              &:hover * {
                fill: ${theme.colors.red};
              }
            }
          `}
        >
          <StyledArrow />
        </Box>
        <Box
          pos="absolute"
          top={["3em", "3em", "2em"]}
          right={["3em", "3em", "2em"]}
          p={["1em"]}
          pointerEvents="all"
          onClick={() => {
            visibleFunc(false)
          }}
          cursor="pointer"
          css={css`
            @media (hover) {
              &:hover * {
                color: ${theme.colors.brown};
              }
            }
          `}
        >
          <Icon
            name="cross"
            w={["4em", "4.2em", "2vw"]}
            h={["5em", "3em", "1.8vw"]}
            zIndex="2"
            color="red"
          />
        </Box>
        {text &&
          (isVideo ? (
            <Icon
              name="play"
              color="red"
              w={["6em", "5em", "2vw"]}
              h={["6em", "5em", "2vw"]}
              pos="absolute"
              bottom={["5em", "4em", "2vw"]}
              right={["4em", "4em", "2vw"]}
              zIndex="2"
              display={["initial", "initial", "none"]}
              onClick={handleSetViewText}
              cursor="pointer"
              pointerEvents="all"
              css={css`
                @media (hover) {
                  &:hover {
                    color: black;
                  }
                }
              `}
            />
          ) : (viewText ? (
            <Icon
              name="gallery"
              color="red"
              w={["6em", "5em", "2vw"]}
              h={["6em", "5em", "2vw"]}
              pos="absolute"
              bottom={["5em", "4em", "2vw"]}
              right={["4em", "4em", "2vw"]}
              zIndex="2"
              display={["initial", "initial", "none"]}
              onClick={handleSetViewText}
              cursor="pointer"
              pointerEvents="all"
              css={css`
                @media (hover) {
                  &:hover {
                    color: black;
                  }
                }
              `}
            />
          ) : (
            <Icon
              name="read"
              color="red"
              w={["6em", "5em", "2vw"]}
              h={["6em", "5em", "2vw"]}
              pos="absolute"
              bottom={["5em", "4em", "2vw"]}
              right={["4em", "4.5em", "2vw"]}
              zIndex="2"
              display={["initial", "initial", "none"]}
              cursor="pointer"
              pointerEvents="all"
              onClick={handleSetViewText}
              css={css`
                @media (hover) {
                  &:hover {
                    color: white;
                  }
                }
              `}
            />
          )))}
      </AnimatedIconsFrame>
    </AnimatedFrame>
  )
}

export default Lightbox
