import React, { useState, useEffect } from "react"

import { useSprings, animated } from "react-spring"
import { useDrag } from "react-use-gesture"
import { Box, Flex } from "@chakra-ui/core"
import styled from "@emotion/styled"
import Arrow from "containers/arrow_right.inline.svg"
import { css } from "@emotion/core"

export const StyledArrow = styled(Arrow)`
  height: 2.5em;
  width: auto;
  position: absolute;
  top: 48%;
  right: 4em;
  z-index: 10;

  @media (min-width: 48em) {
    height: 2em;
  }

  @media (min-width: 75em) {
    height: 1.5em;
  }
`

const StyledArrowLeft = styled(StyledArrow)`
  transform: rotate(180deg);
  transform-origin: center center;
  left: 2em;
`

const StyledArrowRight = styled(StyledArrow)`
  right: 2em;
`

const ImageSlider = ({ children = [], viewImageInfo }) => {
  const [slide, setSlide] = useState(0)

  // Initialize slides with spring
  const [springProps, setSpringProps] = useSprings(
    children && children.length,
    index => ({
      offset: index,
    })
  )

  useEffect(() => {
    setSlide(0)
  }, [children])

  useEffect(() => {
    setSpringProps(index => ({ offset: index - slide }))
    // onSlideChange(slide);
  }, [slide, setSpringProps])

  // drag image
  const bind = useDrag(({ swipe: [swipeX] }) => {
    swipeX === -1 && nextSlide()
    swipeX === 1 && previousSlide()
  })

  if (!children || children.length === 0) {
    return null
  }

  const nextSlide = () => setSlide(state => (state + 1) % children.length)

  const previousSlide = () =>
    setSlide(state => (state - 1 + children.length) % children.length)

  const childrenLength = children && children.length
  const many = childrenLength > 1

  return (
    <>
      <Box
        pos="relative"
        w={["100%", "100%", "100%"]}
        h={["100%", "100%", "100%"]}
        overflow="hidden"
      >
        {many && (
          <Box
            pos="absolute"
            top="0"
            left="0"
            w="50%"
            h="100%"
            onClick={() => previousSlide()}
            zIndex="1"
            // cursor={childrenLength > 1 && "url(/lightbox_arrow_left.svg), auto"}
            css={css`
              &:hover .arrow-left * {
                fill: #e75146;
              }
            `}
            cursor="pointer"
            {...bind()}
          >
            <StyledArrowLeft className="arrow-left" />
          </Box>
        )}
        {many && (
          <Box
            pos="absolute"
            top="0"
            left="50%"
            w="50%"
            h="100%"
            onClick={() => nextSlide()}
            zIndex="1"
            // cursor={childrenLength > 1 && "url(/lightbox_arrow_right.svg), auto"}
            css={css`
              &:hover .arrow-right * {
                fill: #e75146;
              }
            `}
            cursor="pointer"
            {...bind()}
          >
            <StyledArrowRight className="arrow-right" />
          </Box>
        )}
        <Box px={["8em", "7em", "6em"]} py={["12em", "12em", "7em"]} w="100%">
          {springProps &&
            springProps.map(({ offset }, index) => (
              <animated.div
                key={index}
                style={{
                  transform: offset.interpolate(
                    offset => `translate3d(${offset * 100.5}%, 0, 0)`
                  ),
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  willChange: "transform",
                  padding: "inherit",
                  bottom: 0,
                  right: 0,
                  margin: "auto",
                }}
              >
                {children[index]}
              </animated.div>
            ))}
        </Box>
      </Box>
      {childrenLength > 1 && (
        <Flex
          w="100%"
          h="auto"
          pos={["fixed", "fixed", "absolute"]}
          bottom={["0.90em", "0.68em", "1em"]}
          left="0"
          color="white"
          align="center"
          justify="center"
          fontSize={["6xl", "6xl", "3xl"]}
          visibility={viewImageInfo ? "visible" : "hidden"}
        >{`${slide + 1}/${childrenLength}`}</Flex>
      )}
    </>
  )
}

export default ImageSlider
