import React from "react"
import styles from "./lightbox.module.css"

import { Box, Flex } from "@chakra-ui/core"
import ReactMarkdown from "react-markdown"
import RemarkBreaks from "remark-breaks"

const LightboxText = ({ author, title, text, subtitle, useAlternativeFont = false }) => {
  return (
    <Flex
      direction="column"
      py={["13vw", "6vw", "3vw"]}
      pl={["3vw", "3vw", "3vw"]}
      pr={["16vw", "12vw", "6vw"]}
    >
      {author && (
        <Box color="brown" fontSize={["6xl", "5xl", "3xl"]}>
          {author}
        </Box>
      )}
      {title && (
        <Box
          color="red"
          pb={["0.5em", "0.5em", "0.05em"]}
          fontSize={["6xl", "5xl", "3xl"]}
          lineHeight={["1.25", "1.2", "1.15em"]}
        >
          {title}
        </Box>
      )}
      {subtitle && (
        <Box
          color="green"
          pb={["0.5em", "0.5em", "0.05em"]}
          fontSize={["6xl", "5xl", "3xl"]}
          lineHeight={["1.25", "1.2", "1.15em"]}
        >
          {subtitle}
        </Box>
      )}
      {text && (
        <Box
          color="black"
          fontSize={useAlternativeFont ? ["4xl", "2xl", "lg"] : ["4xl", "3xl", "xl"]}
          fontFamily={useAlternativeFont ? "Overpass" : null}
          pt={["0.5vw", "0.5vw", "0.5vw"]}
        >
          <ReactMarkdown
            source={text}
            className={[styles.lightbox, useAlternativeFont && styles.altFont].filter(e => !!e).join(' ')}
            plugins={[RemarkBreaks]}
          />
        </Box>
      )}
    </Flex>
  )
}

export default LightboxText
